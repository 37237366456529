import * as React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoaderSection } from "../common";
import AuthService from "../auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../common/Message";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { statuses, userTypes } from "../common/constants";
import { appTheme } from "../utils";

const Auth = new AuthService();
export default function User(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [store, setStore] = React.useState("");
  const [stores, setStores] = React.useState([]);
  const [franchisor, setFranchisor] = React.useState("");
  const [franchisors, setFranchisors] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [storeError, setStoreError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [userType, setUserType] = React.useState(2);
  const [userStatus, setUserStatus] = React.useState({
    id: "1",
    title: "Active",
  });
  const [userStatusError, setUserStatusError] = React.useState("");
  const [userTypeError, setUserTypeError] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openUserType, setOpenUserType] = React.useState(false);

  React.useEffect(() => {
    if (id > 0) {
      setLoading(true);
    }
    Auth.fetch("/setup/franchisors")
      .then((res2) => {
        if (res2.ack) {
          let franchisor_id = res2.franchisors[0].id;
          setFranchisors(res2.franchisors);
          setFranchisor(franchisor_id);
          Auth.fetch("/store/stores?franchisor_id=" + franchisor_id)
            .then((res1) => {
              if (res1.ack) {
                let stores1 = res1.stores;
                setStores(stores1);
                if (id > 0) {
                  Auth.fetch(`/user/user?id=${id}`)
                    .then((res) => {
                      setLoading(false);
                      if (res.ack) {
                        setFirstName(res.user.first_name);
                        setLastName(res.user.last_name);
                        setEmail(res.user.email);
                        // const userType = userTypes.find(
                        //   (obj) => obj.id == res.user.user_type
                        // );
                        setUserType(res.user.user_type);
                        // const userStore = stores1.find(
                        //   (obj) => obj.id == res.user.store_id
                        // );
                        setStore(res.user.store_id);
                        const userStatus = statuses.find(
                          (obj) => obj.id == res.user.status
                        );
                        setUserStatus(userStatus);
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                }
              } else {
                setStores([]);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickCancel = () => {
    navigate("/users");
  };
  const onChangeUserType = (e, values) => {
    setOpenUserType(false);
    setUserType(e.target.value);
  };
  const onChangeUserStatus = (e, values) => {
    setUserStatus(values);
  };
  const loadStores = (franchisor_id) => {
    setLoading(true);
    Auth.fetch("/store/stores?franchisor_id=" + franchisor_id)

      .then((res1) => {
        setLoading(false);
        if (res1.ack) {
          let stores1 = res1.stores;
          if (stores1.length > 0) {
            setStores(stores1);
            setStore(stores1[0].id);
          } else {
            setStores([]);
            setStore("");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName === "") {
      setFirstNameError("Required");
      return;
    } else {
      setFirstNameError("");
    }
    if (lastName === "") {
      setLastNameError("Required");
      return;
    } else {
      setLastNameError("");
    }
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email === "") {
      setEmailError("Required");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email");
      return;
    } else {
      setEmailError("");
    }
    if (!(id > 0) && password === "") {
      setPasswordError("Required");
      return;
    } else {
      setPasswordError("");
    }
    if (userType === undefined || userType === null || Number(userType) == 0) {
      setUserTypeError("Please select user type");
      return;
    } else {
      setUserTypeError("");
    }
    if (Number(userType) == 2) {
      if (store === "") {
        setStoreError("Please select store");
        return;
      } else {
        setStoreError("");
      }
    }
    if (userStatus === null || userStatus === undefined) {
      setUserStatusError("Please select user status");
      return;
    } else {
      setUserStatusError("");
    }
    // set franchisor check
    // set user type check

    // set store check -> if use type is selected as franchisee

    if (id > 0) {
      setLoading(true);
      Auth.fetch("/user/user", {
        method: "PUT",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          store_id: store,
          password: password,
          franchisor_id: franchisor,
          user_status: userStatus.id,
          user_type: userType,
          id: id,
        }),
      })
        .then((res) => {
          setLoading(false);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/users");
            }, 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(true);
      Auth.fetch("/user/user", {
        method: "POST",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          store_id: store,
          password: password,
          status: userStatus.id,
          user_type: userType,
          franchisor_id: franchisor,
        }),
      })
        .then((res) => {
          setLoading(false);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/users");
            }, 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <LoaderSection state={loading} />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> {id > 0 ? "Update" : "Add"} User</h1>

        <Grid container>
          <Grid item xs={11} sm={8} md={7} lg={5} xl={4} sx={{}}>
            <TextField
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              size="small"
              label="First Name"
              id="first_name"
              error={firstNameError === "" ? false : true}
              helperText={firstNameError}
            />

            <TextField
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              size="small"
              label="Last Name"
              id="last_name"
              error={lastNameError === "" ? false : true}
              helperText={lastNameError}
            />
            <br />
            <TextField
              type="email"
              size="small"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              label="Email"
              id="email"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
            <br />
            <TextField
              type="password"
              size="small"
              autoComplete="off"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              label="Password"
              id="password"
              error={passwordError === "" ? false : true}
              helperText={passwordError}
            />
            <FormControl
              sx={{ width: "100%", marginLeft: "8px", marginTop: "8px" }}
            >
              <InputLabel id="franchisor-label">Frachisor</InputLabel>
              <Select
                size="small"
                labelId="franchisors-label"
                id="franchisor"
                value={franchisor}
                disabled={Auth.getUserType() != 0 ? true : false}
                label="Franchisor"
                onChange={(e) => {
                  setFranchisor(e.target.value);
                  loadStores(e.target.value);
                }}
              >
                {franchisors.map((ss) => {
                  return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {/* <Autocomplete
              id="user-type"
              size="small"
              options={userTypes}
              open={openUserType}
              getOptionLabel={(option) => option.title}
              value={userType}
              onChange={onChangeUserType}
              onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="User Type"
                  autoComplete="off"
                  onClick={() => setOpenUserType(true)}
                  placeholder="User Type"
                  error={userTypeError === "" ? false : true}
                  helperText={userTypeError}
                />
              )}
            /> */}
            <FormControl
              sx={{ width: "100%", marginLeft: "8px", marginTop: "8px" }}
            >
              <InputLabel id="franchisor-label">User Type</InputLabel>
              <Select
                size="small"
                labelId="user-type-label"
                id="user-type"
                value={userType}
                label="User Type"
                onChange={onChangeUserType}
              >
                {userTypes.map((ss) => {
                  return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {userType && userType == 2 && (
              <FormControl
                sx={{
                  width: "100%",
                  marginLeft: "8px",
                  marginTop: "8px",
                }}
                size="small"
              >
                <InputLabel id="store-label">Store</InputLabel>
                <Select
                  labelId="stores-label"
                  id="store"
                  value={store}
                  error={storeError === "" ? false : true}
                  helperText={storeError}
                  label="Store"
                  onChange={(e) => {
                    setStore(e.target.value);
                  }}
                >
                  {stores.map((ss) => {
                    return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                  })}
                </Select>
                {storeError !== "" && (
                  <p
                    style={{
                      color: "#d33f3c",
                      textAlign: "left",
                      paddingLeft: "10px",

                      marginTop: "3px",
                    }}
                  >
                    {storeError}
                  </p>
                )}
              </FormControl>
            )}
            <Autocomplete
              size="small"
              id="user-status"
              options={statuses}
              getOptionLabel={(option) => option.title}
              value={userStatus}
              onChange={onChangeUserStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={userStatusError === "" ? false : true}
                  variant="standard"
                  label="Status"
                  placeholder="User Status"
                  helperText={userStatusError}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box style={{ float: "right", marginTop: "40px" }}>
          <Button
            variant="contained"
            sx={{
              marginRight: "10px",
              backgroundColor: "#32303B",
              "&:hover": {
                backgroundColor: "#32303B", // Change the color on hover
              },
            }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: appTheme.btnColor,
              "&:hover": {
                backgroundColor: appTheme.btnColor, // Change the color on hover
              },
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
        <Message type={type} msg={loadMessage} />
      </Box>
    </>
  );
}
