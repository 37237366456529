import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";

export default function Index() {
  return (
    <HashRouter basename="/">
      <App />
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
