
export const screenTypes = [
    { id: "1", title: "Images" },
    { id: "2", title: "Video" },
];

export const statuses = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
];

export const userTypes = [
    { id: "1", title: "Admin" },
    { id: "2", title: "Franchisee" },
];
