import * as React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { appTheme } from "../utils";
import AuthService from "../auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../common/Message";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { statuses } from "../common/constants";
import { LoaderSection } from "../common";
const Auth = new AuthService();
export default function Store(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [title, setTitle] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [franchisor, setFranchisor] = React.useState("");
  const [franchisors, setFranchisors] = React.useState([]);
  const [storeStatusError, setStoreStatusError] = React.useState("");
  const [storeStatus, setStoreStatus] = React.useState({
    id: "1",
    title: "Active",
  });
  const [franchisorError, setFranchisorError] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (id > 0) {
      setLoading(true);
    }
    Auth.fetch("/setup/franchisors")
      .then((res2) => {
        if (res2.ack) {
          let franchisor_id = res2.franchisors[0].id;
          setFranchisors(res2.franchisors);
          setFranchisor(franchisor_id);
          if (id > 0) {
            Auth.fetch(`/store/store?id=${id}`)
              .then((res) => {
                setLoading(false);
                if (res.ack) {
                  setTitle(res.store.title);
                  setEmail(res.store.email);
                  setPhone(res.store.phone);
                  const storeStatus = statuses.find(
                    (obj) => obj.id == res.store.status
                  );
                  setStoreStatus(storeStatus);
                }
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const onClickCancel = () => {
    navigate("/stores");
  };
  const onChangeFranchisor = (e, values) => {
    // setFranchisor(values);
  };
  const onChangeStoreStatus = (e, values) => {
    setStoreStatus(values);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }
    if (email === "") {
      setEmailError("Required");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email");
      return;
    } else {
      setEmailError("");
    }
    if (phone === "") {
      setPhoneError("Required");
      return;
    } else {
      setPhoneError("");
    }
    if (storeStatus === null || storeStatus === undefined) {
      setStoreStatusError("Please select store status");
      return;
    } else {
      setStoreStatusError("");
    }
    if (id > 0) {
      setLoading(true);
      Auth.fetch("/store/store", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          email: email,
          phone: phone,
          status: storeStatus?.id,
          id: id,
        }),
      })
        .then((res) => {
          setLoading(false);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/stores");
            }, 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(true);
      Auth.fetch("/store/store", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          email: email,
          phone: phone,
          franchisor_id: franchisor,
          status: storeStatus.id,
        }),
      })
        .then((res) => {
          setLoading(false);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/stores");
            }, 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <LoaderSection state={loading} />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> {id > 0 ? "Update" : "Add"} Store</h1>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={11} sm={8} md={7} lg={5} xl={4}>
            <TextField
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              label="Title"
              id="title"
              size="small"
              error={titleError === "" ? false : true}
              helperText={titleError}
            />
            <TextField
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              label="Email"
              id="email"
              size="small"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
            <TextField
              type="phone"
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              label="Phone"
              id="phone"
              size="small"
              error={phoneError === "" ? false : true}
              helperText={phoneError}
            />
            <FormControl
              sx={{ width: "100%", marginLeft: "8px", marginTop: "8px" }}
              size="small"
            >
              <InputLabel id="franchisor-label">Frachisor</InputLabel>
              <Select
                labelId="franchisors-label"
                id="franchisor"
                value={franchisor}
                disabled={Auth.getUserType() != 0 ? true : false}
                label="Franchisor"
                onChange={(e) => {
                  setFranchisor(e.target.value);
                }}
              >
                {franchisors.map((ss) => {
                  return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <Autocomplete
              id="store-status"
              sx={{ marginTop: "8px" }}
              options={statuses}
              size="small"
              getOptionLabel={(option) => option.title}
              value={storeStatus}
              onChange={onChangeStoreStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={storeStatusError === "" ? false : true}
                  variant="standard"
                  label="Status"
                  placeholder="Store Status"
                  helperText={storeStatusError}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box style={{ float: "right", marginTop: "40px" }}>
          <Button
            variant="contained"
            sx={{
              marginRight: "10px",
              backgroundColor: "#32303B",
              "&:hover": {
                backgroundColor: "#32303B", // Change the color on hover
              },
            }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: appTheme.btnColor,
              "&:hover": {
                backgroundColor: appTheme.btnColor, // Change the color on hover
              },
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>

        <Message type={type} msg={loadMessage} />
      </Box>
    </>
  );
}
