import Swal from "sweetalert2";
// ========================SideBarIccon============================
import PermMediaIcon from "@mui/icons-material/PermMedia";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StoreIcon from "@mui/icons-material/Store";
import AuthService from "../auth/AuthService";
// ========================SideBarIccon============================

//=========== App theme==============
let appTheme = {
  btnColor: "#F49D2B",
  headerBg: "#FFFFFF",
  storeColor: "orange",
  cancelBtn: "#32303B",
};

const Auth = new AuthService();

//=================confirmation Modal for Delete operation============
const confirmationModal = (confirmationAction) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this record !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: appTheme.btnColor,
    cancelButtonColor: appTheme.cancelBtn,
    confirmButtonText: "Yes",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      // Swal.fire(
      //   'Deleted!',
      //   'Your file has been deleted.',
      //   'success'
      // )
      confirmationAction();
    }
  });
};
//=================confirmation Modal for Delete operation============
const confirmationMediaModal = (operation, stores, row, confirmAction) => {
  Swal.fire({
    title: `Are you sure?`,
    icon: "warning",
    html: `${
      Auth.getUserType() < 2
        ? "This media is associated with below stores."
        : `Are you sure to want to ${operation} this media.`
    }
    <br/> <br/> <b>
   ${Auth.getUserType() < 2 ? stores.join() : ""}
    </b>
    <br/> <br/> <br/> 
    <button type="button" id="cancel" 
class="btn btn-cancel
swl-cstm-btn-cancel"  style=" border-radius: 0.3em;
background-color: rgb(50, 48, 59);
color: #fff;font-size: 1em;
margin: 0.3125em;
cursor: pointer;
padding: 0.5em 1.1em;
transition: box-shadow .1s;
box-shadow: 0 0 0 3px transparent;
font-weight: 500;" >Cancel</button>

<button type="button" id="delete-current"style=" border-radius: 0.3em;
background-color: rgb(244, 157, 43);
color: #fff;
font-size: 1em;
margin: 0.3125em;
box-shadow: 0 0 0 3px transparent;
padding: 0.5em 1.1em;
  border: 2px solid #fff;
transition: box-shadow .1s;
cursor: pointer;
font-weight: 500;"
class="btn btn-no swl-cstm-btn-no-jst-prceed">
${operation} 
Current </button> 
${
  Auth.getUserType() < 2
    ? `<button type="button" id="delete-all"
          class="btn btn-yes swl-cstm-btn-yes-sbmt-rqst" style=" border-radius: 0.3em;
background-color: rgb(244, 157, 43);
color: #fff;
font-size: 1em;
margin: 0.3125em;
box-shadow: 0 0 0 3px transparent;
padding: 0.5em 1.1em;
  border: 2px solid #fff;
 
transition: box-shadow .1s;
cursor: pointer;
font-weight: 500;">
          ${operation} All</button>`
    : ""
} `,

    showCancelButton: false,
    showConfirmButton: false,
    didOpen: () => {
      const deleteAll = document.querySelector("#delete-all");
      const deleteCurrent = document.querySelector("#delete-current");
      const cancel = document.querySelector("#cancel");

      if (deleteAll) {
        deleteAll.addEventListener("click", () => {
          console.log("Delete All");
          confirmAction(row, 1);
          Swal.close();
        });
      }
      deleteCurrent.addEventListener("click", () => {
        console.log("Delete deleteCurrent");
        confirmAction(row);
        Swal.close();
      });

      cancel.addEventListener("click", () => {
        console.log("cancel was Cliked.");
        Swal.close();
      });
    },
  });
};
// ========================SideBar Rotes ============================
let defaultIconColor = "#F49D2B";
let activeIcconColor = "white";
let activeListBgColor = "#F49D2B";
let onHoverListBgColor = "#45558c";

let listItem = (location, userType) => {
  if (userType) {
    return [
      {
        id: 1,
        pathname: "/",
        tittleName: "Dashboard",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${location?.pathname === "/" && activeListBgColor}`,
        },
        Icon: DashboardIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/" ? activeIcconColor : defaultIconColor
          }`,
        },
      },
      {
        id: 2,
        pathname: "/media",
        tittleName: "Media",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${
            (location?.pathname === "/media" ||
              location?.pathname === "/media/new") &&
            activeListBgColor
          }`,
        },
        Icon: PermMediaIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/media" ||
            location?.pathname === "/media/new"
              ? activeIcconColor
              : defaultIconColor
          }`,
        },
      },
      {
        id: 3,
        pathname: "/screens",
        tittleName: "Screens",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${
            (location?.pathname === "/screens" ||
              location?.pathname === "/screen/new") &&
            activeListBgColor
          }`,
        },
        Icon: VideoSettingsIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/screens" ||
            location?.pathname === "/screen/new"
              ? activeIcconColor
              : defaultIconColor
          }`,
        },
      },
      {
        id: 4,
        pathname: "/stores",
        tittleName: "Stores",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${
            (location?.pathname === "/stores" ||
              location?.pathname === "/store/new") &&
            activeListBgColor
          }`,
        },
        Icon: StoreIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/stores" ||
            location?.pathname === "/store/new"
              ? activeIcconColor
              : defaultIconColor
          }`,
        },
      },
      {
        id: 5,
        pathname: "/users",
        tittleName: "Users",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${
            (location?.pathname === "/users" ||
              location?.pathname === "/user/new") &&
            activeListBgColor
          }`,
        },
        Icon: ManageAccountsIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/users" ||
            location?.pathname === "/user/new"
              ? activeIcconColor
              : defaultIconColor
          }`,
        },
      },
    ];
  } else {
    return [
      {
        id: 1,
        pathname: "/",
        tittleName: "Dashboard",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${location?.pathname === "/" && activeListBgColor}`,
        },
        Icon: DashboardIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/" ? activeIcconColor : defaultIconColor
          }`,
        },
      },
      {
        id: 2,
        pathname: "/media",
        tittleName: "Media",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${
            (location?.pathname === "/media" ||
              location?.pathname === "/media/new") &&
            activeListBgColor
          }`,
        },
        Icon: PermMediaIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/media" ||
            location?.pathname === "/media/new"
              ? activeIcconColor
              : defaultIconColor
          }`,
        },
      },
      {
        id: 3,
        pathname: "/screens",
        tittleName: "Screens",
        listButStyle: {
          "&:hover": {
            backgroundColor: onHoverListBgColor,
          },
          backgroundColor: `${
            (location?.pathname === "/screens" ||
              location?.pathname === "/screen/new") &&
            activeListBgColor
          }`,
        },
        Icon: VideoSettingsIcon,
        icconStyle: {
          fontSize: "30px",
          color: `${
            location?.pathname === "/screens" ||
            location?.pathname === "/screen/new"
              ? activeIcconColor
              : defaultIconColor
          }`,
        },
      },
    ];
  }
};

// if (Auth.getUserType() < 2) {
//   listItem = (location,userType) => {
//     return [
//       {
//         id: 1,
//         pathname: "/",
//         tittleName: "Dashboard",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${location?.pathname === "/" && activeListBgColor}`,
//         },
//         Icon: DashboardIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/" ? activeIcconColor : defaultIconColor
//           }`,
//         },
//       },
//       {
//         id: 2,
//         pathname: "/media",
//         tittleName: "Media",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${
//             (location?.pathname === "/media" ||
//               location?.pathname === "/media/new") &&
//             activeListBgColor
//           }`,
//         },
//         Icon: PermMediaIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/media" ||
//             location?.pathname === "/media/new"
//               ? activeIcconColor
//               : defaultIconColor
//           }`,
//         },
//       },
//       {
//         id: 3,
//         pathname: "/screens",
//         tittleName: "Screens",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${
//             (location?.pathname === "/screens" ||
//               location?.pathname === "/screen/new") &&
//             activeListBgColor
//           }`,
//         },
//         Icon: VideoSettingsIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/screens" ||
//             location?.pathname === "/screen/new"
//               ? activeIcconColor
//               : defaultIconColor
//           }`,
//         },
//       },
//       {
//         id: 4,
//         pathname: "/stores",
//         tittleName: "Stores",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${
//             (location?.pathname === "/stores" ||
//               location?.pathname === "/store/new") &&
//             activeListBgColor
//           }`,
//         },
//         Icon: StoreIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/stores" ||
//             location?.pathname === "/store/new"
//               ? activeIcconColor
//               : defaultIconColor
//           }`,
//         },
//       },
//       {
//         id: 5,
//         pathname: "/users",
//         tittleName: "Users",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${
//             (location?.pathname === "/users" ||
//               location?.pathname === "/user/new") &&
//             activeListBgColor
//           }`,
//         },
//         Icon: ManageAccountsIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//            ( location?.pathname === "/users" ||
//             location?.pathname === "/user/new")
//               ? activeIcconColor
//               : defaultIconColor
//           }`,
//         },
//       },
//     ];
//   };
// } else {
//   listItem = (location) => {
//     return [
//       {
//         id: 1,
//         pathname: "/",
//         tittleName: "Dashboard",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${location?.pathname === "/" && activeListBgColor}`,
//         },
//         Icon: DashboardIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/" ? activeIcconColor : defaultIconColor
//           }`,
//         },
//       },
//       {
//         id: 2,
//         pathname: "/media",
//         tittleName: "Media",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${
//             (location?.pathname === "/media" ||
//               location?.pathname === "/media/new") &&
//             activeListBgColor
//           }`,
//         },
//         Icon: PermMediaIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/media" ||
//             location?.pathname === "/media/new"
//               ? activeIcconColor
//               : defaultIconColor
//           }`,
//         },
//       },
//       {
//         id: 3,
//         pathname: "/screens",
//         tittleName: "Screens",
//         listButStyle: {
//           "&:hover": {
//             backgroundColor: onHoverListBgColor,
//           },
//           backgroundColor: `${
//             (location?.pathname === "/screens" ||
//               location?.pathname === "/screen/new") &&
//             activeListBgColor
//           }`,
//         },
//         Icon: VideoSettingsIcon,
//         icconStyle: {
//           fontSize: "30px",
//           color: `${
//             location?.pathname === "/screens" ||
//             location?.pathname === "/screen/new"
//               ? activeIcconColor
//               : defaultIconColor
//           }`,
//         },
//       },
//     ];
//   };
// }

export { confirmationModal, confirmationMediaModal, listItem, appTheme };
