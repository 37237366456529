import * as React from "react";

import { Button, Pagination, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AuthService from "../auth/AuthService";
import SearchIcon from "@mui/icons-material/Search";
import { Link as NavLink } from "react-router-dom";
import { confirmationModal, appTheme, confirmationMediaModal } from "../utils";
import { PreviewModal, DraggableTable, LoaderSection } from "../common";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    width: "100%",
    marginTop: "10px",
  },
  tblsearch: {
    float: "left",
    width: "100%",
  },
  addIcon: {
    float: "right",
  },
}));

const Auth = new AuthService();
export default function MediaList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [screens, setScreens] = React.useState([]);
  const [screen, setScreen] = React.useState("");

  const [stores, setStores] = React.useState([]);
  const [store, setStore] = React.useState("");

  const [mediaType, setMediaType] = React.useState(1);

  const [images, setImages] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(50);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  //====================Preview Section==========
  const [openPrevewModal, setOpenPreviewModal] = React.useState(false);
  const [previewData, setpreviewData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  //====================Preview Section==========

  React.useEffect(() => {
    setLoading(true);
    Auth.fetch("/store/stores").then((res1) => {
      setLoading(false);
      if (res1.ack) {
        setStores(res1.stores);
        // if (res1.stores.length > 0) {
        //   setStore(res1.stores[0].id);
        //   loadScreens(res1.stores[0].id);
        // }
      } else {
        setStores([]);
      }
    });
  }, []);

  const loadScreens = (store_id) => {
    setLoading(true);
    Auth.fetch("/screen/stores-screens?store_id=" + store_id).then((res) => {
      setLoading(false);
      if (res.ack) {
        setScreens(res.screens);
        // if (res.screens.length > 0) {
        //   let checkScreen = res.screens.filter((s) => s.id == screen);
        //   if (checkScreen.length == 0) {
        //     // if the screen exist in the new store, keep it selected, or select the first screen in the new list
        //     setScreen(res.screens[0].id);
        //     loadMedia(store_id, res.screens[0].id, mediaType);
        //   } else {
        //     loadMedia(store_id, screen, mediaType);
        //   }
        // }
      } else {
        setScreens([]);
      }
    });
  };
  const loadMedia = (store_id, screen_id, media_type) => {
    setImages([]);
    if (Number(screen_id) > 0 && Number(media_type) > 0) {
      setLoading(true);
      Auth.post(`/media/media`, {
        limit: limit,
        page: page,
        store_id: store_id,
        screen_id: screen_id,
        // media_type: media_type,
      })
        .then((res) => {
          setLoading(false);
          if (res.ack) {
            const images1 = res.images;
            setImages(images1);
            setTotalPages(Math.ceil(res.total_count / limit));
          } else {
            setImages([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  React.useEffect(() => {
    loadMedia(store, screen, mediaType);
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    window.location = "media/" + id;
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const confirmToDelete = (row, all) => {
    const params = {
      method: "DELETE",
      body: JSON.stringify({
        id: row.id,
        file: row.file,
        all: all,
        screen_id: row.screen_id,
        store_id: row.store_id,
      }),
    };
    Auth.fetch(`/media/media`, params)
      .then((res) => {
        if (res.ack) {
          setType("info");
          setLoadMessage(res.message);

          let images1 = images.filter((i, ind) => i.id !== row.id);
          afterSort(images1);

          if (all != undefined) {
            afterDeleteAllSort();
          }
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else {
          setLoadMessage("error");
          setLoadMessage(res.status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (row) => {
    setLoading(true);
    try {
      Auth.fetch(`/media/media-stores?media_id=${row.id}`).then((res1) => {
        if (res1.ack) {
          let stores1 = res1.stores[0].stores.split(",");
          if (stores1.length == 1) {
            setLoading(false);
            confirmationModal(() => {
              confirmToDelete(row);
            });
          } else {
            setLoading(false);
            confirmationMediaModal("Delete", stores1, row, confirmToDelete);
          }
        }
      });
    }
    catch (error) {
      setLoading(false);
    }
  };

  const handleMediaStatus = (row, status) => {
    Auth.fetch(`/media/media-stores?media_id=${row.id}`).then((res1) => {
      if (res1.ack) {
        console.log(res1.stores);
        let stores1 = res1.stores[0].stores.split(",");
        if (stores1.length == 1) {
          updateMediaStatus(row);
        } else {
          confirmationMediaModal("Update", stores1, row, updateMediaStatus);
        }
      }
    });
  };
  const updateMediaStatus = (row, all) => {
    Auth.fetch("/media/media-status", {
      method: "PUT",
      body: JSON.stringify({
        id: row.id,
        status: row.status == 1 ? 0 : 1,
        all: all,
        screen_id: row.screen_id,
        store_id: row.store_id,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          setType("success");
          setLoadMessage(res.message);
          loadMedia(store, screen, mediaType);
        } else {
          setType("error");
          setLoadMessage(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePreviewModal = (row) => {
    setOpenPreviewModal(true);
    setpreviewData(row);
  };
  const afterSort = (images1) => {
    Auth.fetch(`/media/media-sort`, {
      method: "PUT",
      body: JSON.stringify({
        images: images1,
        store_id: store,
        screen_id: screen,
      }),
    })
      .then((res) => {
        // window.location.reload();
        loadMedia(store, screen, mediaType);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const afterDeleteAllSort = () => {
    setLoading(true);
    Auth.fetch(`/media/media-sort-all-stores`, {
      method: "PUT",
      body: JSON.stringify({
        screen_id: screen
      }),
    })
      .then((res) => {
        // window.location.reload();
        loadMedia(store, screen, mediaType);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem("MediaFilters");
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    Object.assign(existingFilters, newValues);
    existingFilters.timestamp = new Date().getTime();
    const updatedFiltersString = JSON.stringify(existingFilters);
    localStorage.setItem("MediaFilters", updatedFiltersString);
  };

  // Function to get filters or set defaults from localStorage
  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem("MediaFilters");
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000; // 30 minutes expiration

    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {
      if (!existingFilters.store) {
        existingFilters.store = "";
      }

      if (!existingFilters.screen) {
        existingFilters.screen = "";
      }

      return existingFilters;
    } else {
      localStorage.removeItem("MediaFilters");
      return {};
    }
  };

  React.useEffect(() => {
    const initialState = getFiltersOrDefault();
    if (initialState.store ) {
      setStore(initialState.store);
      loadScreens(initialState.store);
      if (initialState.screen) {
        setScreen(initialState.screen);
        loadMedia(initialState.store, initialState.screen, mediaType);
      } else {
        // No screen in localStorage, load first screen for the store
        loadScreens(initialState.store);
      }
    }else if (stores.length > 0) {
      // No store in localStorage, use the first store
      const firstStore = stores[0].id;
      setStore(firstStore);
      updateFilters({ store: firstStore, screen: "" });
  
      loadScreens(firstStore);
    }
    

    initialState.timestamp = new Date().getTime();
    updateFilters(initialState);
  }, [stores]);

  return (
    <div>
      <LoaderSection state={loading} />
      {previewData !== null && openPrevewModal && (
        <PreviewModal
          open={openPrevewModal}
          handleClose={() => {
            setOpenPreviewModal(false);
            setpreviewData(null);
          }}
          data={previewData}
        />
      )}
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-medias"}}>Import Medias</Button> */}
      <div className={classes.tblTopDiv}>
        <div className={classes.tblsearch}>
          <Grid container spacing={1.3} sx={{ marginBottom: "15px" }}>
            <Grid item xs={12} sm={6} md={3.5} lg={2.5}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="store-label">Store</InputLabel>
                <Select
                  size="small"
                  labelId="stores-label"
                  id="store"
                  value={store}
                  label="Store"
                  onChange={(e) => {
                    setStore(e.target.value);
                    updateFilters({ store: e.target.value, screen: '' })
                    setImages([])
                    loadScreens(e.target.value);
                  }}
                >
                  {stores.map((ss) => {
                    return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3.5} lg={2.5}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="screen-label">Screen</InputLabel>
                <Select
                  size="small"
                  labelId="screens-label"
                  id="screen"
                  value={screen}
                  label="Screen"
                  onChange={(e) => {
                    setScreen(e.target.value);
                    updateFilters({ screen: e.target.value })

                    loadMedia(store, e.target.value, mediaType);
                  }}
                >
                  {screens.map((ss) => {
                    return (
                      <MenuItem value={ss.id}>
                        {ss.title} {ss.screen_status == 0 ? " (Inactive)" : ""}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={5} md={3} lg={2.5}>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <InputLabel id="mediaType-label">Media Type</InputLabel>
                <Select
                  size="small"
                  labelId="mediaType-label"
                  id="mediaType"
                  value={mediaType}
                  label="mediaType"
                  onChange={(e) => {
                    setMediaType(e.target.value);
                    loadMedia(store, screen, e.target.value);
                  }}
                >
                  <MenuItem value={1}>Images</MenuItem>
                  <MenuItem value={2}>Videos</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={7}
              sx={{
                textAlign: "end",
              }}
            >
              <NavLink to="/media/new">
                {/* <Button
                  style={{ height: "55px" }}
                  variant="contained"
                  sx={{
                    backgroundColor: appTheme.btnColor,
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: appTheme.btnColor, // Change the color on hover
                    },
                    
                  }}
                  size="large"
                >
                  Add Media
                </Button> */}
                <AddCircleIcon
                  fontSize="large"
                  style={{
                    color: appTheme.btnColor,
                  }}
                />
              </NavLink>
            </Grid>
          </Grid>

          {/* <Button
            style={{ float: "right", height: "55px" }}
            variant="contained"
            color="primary"
            onClick={Search}
            size="large"
            endIcon={<SearchIcon />}
          >
            Search
          </Button>
          <TextField
            style={{
              float: "right",
              width: "350px",
              height: "10px",
              marginRight: "5px",
            }}
            id="standard-adornment-password"
            type="text"
            label="Search"
            variant="outlined"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton aria-label="Search By Student Name" onClick={Search}>
          //         <SearchIcon />
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
          /> */}
        </div>
      </div>
      {images && images.length > 0 && (
        <DraggableTable
          images={images}
          deleteMedia={handleDelete}
          updateVisibility={() => { }}
          afterSort={afterSort}
          handleMediaStatus={handleMediaStatus}
          handlePreviewModal={handlePreviewModal}
        />
      )}
      {images && images.length > 0 && (
        <div style={{ float: "right", margin: "10px" }}>
          <Pagination
            page={page}
            count={totalPages}
            variant="outlined"
            color="primary"
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}
