const dev = {
  domain: "http://localhost:8741",
  api_domain: "http://localhost:8741/api",
  aws_bucket: "prefectos-signage",
  s3_bucket: "https://d2vriuhatru25d.cloudfront.net/"
};

const uat1 = {
  domain: "http://34.197.161.10:8741",
  api_domain: "http://34.197.161.10:8741/api",
  aws_bucket: "prefectos-signage",
  cloudfront_url: "https://d2vriuhatru25d.cloudfront.net/",  
  cloudfront_url_thumbnails: "https://d2qgyyu8yj06i5.cloudfront.net/",
  s3_bucket: "https://prefectos-signage.s3.us-east-2.amazonaws.com/"
};


const uat = {
  domain: "https://lazs6uum76.execute-api.us-east-2.amazonaws.com/latest",
  api_domain: "https://lazs6uum76.execute-api.us-east-2.amazonaws.com/latest/api",
  aws_bucket: "prefectos-signage",
  cloudfront_url: "https://d2vriuhatru25d.cloudfront.net/",
  cloudfront_url_thumbnails: "https://d2qgyyu8yj06i5.cloudfront.net/",
  s3_bucket: "https://prefectos-signage.s3.us-east-2.amazonaws.com/"
};

const prod = {
  domain: "https://3kmcjs0ovk.execute-api.us-east-2.amazonaws.com/latest",
  api_domain: "https://3kmcjs0ovk.execute-api.us-east-2.amazonaws.com/latest/api",
  aws_bucket: "prefectos-signage-uploads-prod",
  cloudfront_url: "https://d14cs5o3tjy2x4.cloudfront.net/",
  cloudfront_url_thumbnails: "https://dwzufq1m5m02q.cloudfront.net/",
  s3_bucket: "https://prefectos-signage-uploads-prod.s3.us-east-2.amazonaws.com/"
};

module.exports = uat;