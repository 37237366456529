import React, { useState, useLayoutEffect } from "react";
import {
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import { Link as NavLink } from "react-router-dom";
// import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { useLocation } from "react-router-dom";
import { listItem } from "../utils";
import { SideBarDrawer } from "../Layout/Theme";
import AuthService from "../auth/AuthService";

export default function SideBar({ sidebarToggle, setSidebarToggle }) {
  const [userType, setUserType] = useState(false);
  const location = useLocation();
  const Auth = new AuthService();
  useLayoutEffect(() => {
    if (Auth.getUserType() < 2) {
      setUserType(true);
    } else {
      setUserType(false);
    }
  }, [Auth]);

  let sideBarList = listItem(location, userType);

  return (
    <SideBarDrawer variant="permanent" open={sidebarToggle}>
      <List
        component="nav"
        sx={{
          width: "100%",
          padding: "8px 0px",
          margin: "0px",
          backgroundColor: "black",
          height: "100%",
          color: "white",
        }}
      >
        {sideBarList.map((item) => {
          return (
            <ListItemButton
              key={item.id}
              component={NavLink}
              to={item.pathname}
              sx={item.listButStyle}
            >
              <ListItemIcon>
                <item.Icon sx={item.icconStyle} />
              </ListItemIcon>
              <ListItemText primary={item.tittleName} />
            </ListItemButton>
          );
        })}
        {/* <ListItemButton
          component={NavLink}
          to="/setup"
          sx={{
            "&:hover": {
              backgroundColor: "#45558c",
            },
            position: "absolute",
            bottom: "5px",
            left: "0",
            width: "100%",
            backgroundColor: `${location?.pathname === "/setup" && "#1976d2"}`,
          }}
        >
          <ListItemIcon>
            <SettingsApplicationsIcon
              sx={{
                fontSize: "30px",
                color: `${
                  location?.pathname === "/setup" ? "white" : "#29664b"
                }`,
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Setup" />
        </ListItemButton> */}
      </List>
    </SideBarDrawer>
  );
}
