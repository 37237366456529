import "sweetalert2/dist/sweetalert2.min.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import SignIn from "./Components/auth/SignIn";
import ProtectedRoute from "./Routes/ProtectedRoute";
import SignUp from "./Components/auth/SignUp";
import Loading from "./Components/Loading";
import { WebRoutes } from "./Routes/Web";

function App() {
  return (
    <>
      <Routes>
        <Route path="/loading" element={<Loading />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/*================ this function hold all remaing routes ================= */}
        {WebRoutes()}
      </Routes>
    </>
  );
}

export default App;
