import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import AuthService from "../auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../common/Message";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const Auth = new AuthService();

export default function Setup(props) {
  const navigate = useNavigate();
  const params = useParams();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [imageTimeout, setImageTimeout] = React.useState("");
  const [imageTimeoutError, setImageTimeoutError] = React.useState("");
  const [screens, setScreens] = React.useState([]);
  const [screen, setScreen] = React.useState({ id: "", title: "" });

  const [screenError, setScreenError] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Auth.fetch("/screen/screens").then((res) => {
      if (res.ack) {
        setScreens(res.screens);
      } else {
        setScreens([]);
      }
    });
  }, []);

  const onClickCancel = () => {
    navigate("/setups");
  };

  const onChangeScreen = (event, values) => {
    setScreen(values);
    setId(0);
    Auth.fetch(`/setup/setup?screen_id=${values.id}`).then((res) => {
      if (res.ack) {
        setId(res.setup.id);
        setImageTimeout(res.setup.image_timeout);
      } else {
        setId(0);
        setImageTimeout(null);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (imageTimeout === "") {
      setImageTimeoutError("Required");
      return;
    } else {
      setImageTimeoutError("");
    }

    if (id > 0) {
      setLoading(true);
      Auth.fetch("/setup/setup", {
        method: "PUT",
        body: JSON.stringify({
          image_timeout: imageTimeout,
          screen_id: screen.id,
          id: id,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/setup");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      Auth.fetch("/setup/setup", {
        method: "POST",
        body: JSON.stringify({
          image_timeout: imageTimeout,
          screen_id: screen.id,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/setup");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Setup</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={11} sm={6} md={5} lg={4} xl={3}>
          {screens.length > 0 && (
            <Autocomplete
              id="tags-standard"
              options={screens}
              getOptionLabel={(option) => option.title}
              value={screen}
              onChange={onChangeScreen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Screen"
                  placeholder="Screen"
                  error={screenError === "" ? false : true}
                  helperText={screenError}
                />
              )}
            />
          )}
          <br />
          <TextField
            type="number"
            value={imageTimeout}
            onChange={(event) => {
              setImageTimeout(event.target.value);
            }}
            label="Image Timeout (Seconds)"
            id="image_timeout"
            error={imageTimeoutError === "" ? false : true}
            helperText={imageTimeoutError}
          />
          <br />
        </Grid>
      </Grid>
      <Box style={{ float: "right", marginTop: "40px" }}>
        <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Box>

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
