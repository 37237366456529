import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "./Header";
import SideBar from "./SideBar";
import Copyright from "./Copyright";
const Main = styled("main")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "calc( 100% - 64px )",
}));
export default function Layout({ children }) {
  const [sidebarToggle, setSidebarToggle] = useState(true);

  return (
    <>
      <Box>
        <Header
          handleSidebarToggle={() => {
            setSidebarToggle(!sidebarToggle);
          }}
          sidebarToggle={sidebarToggle}
        />
        <Box sx={{ width: "100%", marginTop: "64px" }}>
          <Main>
            <SideBar
              sidebarToggle={sidebarToggle}
              setSidebarToggle={setSidebarToggle}
            />
            <Box sx={{ width: "100%", margin: "15px", 
            overflow: "auto",
            display:"flex", flexDirection:"column",
             }}>
              <Box sx={{
                minHeight:"calc(100vh - 100px)"
              }}>
              {children}
              </Box>
                      <Copyright/>
              
            </Box>

          </Main>
        </Box>
      </Box>
    </>
  );
}
