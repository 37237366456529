import React, { useEffect } from "react";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function Message(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("center");

  useEffect(() => {
    setOpen(props.msg === "" ? false : true);
  }, [props.msg]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function setSliderFunc(event) {
    // Here, we invoke the callback with the new value
    props.setSliderFunc(5);
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={props.type ? props.type : "success"}
          variant="filled"
          {...props}
        >
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
