import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  Avatar,
} from "@mui/material";
//icons and images
import AppLogo from "../assets/images/Logos-01.png";
import MenuOpenIcon from "../assets/images/icons/menu-open.svg";
import MenuCloseIcon from "../assets/images/icons/menu-close.svg";
import UserProfile from "../assets/images/profile-pic.jpg";
import {appTheme} from "../utils"
//styles
import { styled } from "@mui/material/styles";
//context
import AuthService from "../auth/AuthService";
const Appbar = styled(AppBar)(({ theme }) => ({
  height: "64px",
  width: "100%",
  minHeight: "64px",
  background: appTheme.headerBg,
  border: "1px solid #EDE9E9",
}));
const ToolBar = styled(Toolbar)(({ theme }) => ({
  minWidth: "100%",
  "& h4": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#565656",
  },
  "& .MuiTypography-subtitle": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#9C9C9C",
  },
}));

const Applogo = styled("img")(({ theme }) => ({
  width: "180px",
  height: "40px",
  marginRight: "36px",
}));

const MenuIcon = styled("img")(({ theme }) => ({
  width: "18px",
  color: theme.palette.primary.main,
}));

export default function Header({ sidebarToggle, handleSidebarToggle }) {
  const Auth = new AuthService();
  const [anchorEl, setAnchorEl] = useState(null);
  const [screenWidth, setScreenWidth] = useState("");
  const [openMenuOutSide, setOpenMenuOutSide] = useState(false);
  //=================For Mobial Case Show A Menue==================
  // Update the screen width when the window is resized
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    // Attach the resize event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    Auth.logout();
  };

  //menue of Small Screen
  let smallScreenMenu = (
    <React.Fragment>
      {" "}
      <MenuItem onClick={handleMenuClose}>
        {" "}
        {localStorage.getItem("store") !== null && (
          <Typography
            variant="h7"
            sx={{
              fontWeight: "bold",
            }}
          >
            {localStorage.getItem("store")}{" "}
          </Typography>
        )}
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Typography
          variant="h7"
          sx={{
            fontWeight: "bold",
          }}
        >
          {localStorage.getItem("logged_in_user_name") !== null &&
            localStorage.getItem("logged_in_user_name")}
        </Typography>
      </MenuItem>
    </React.Fragment>
  );

  return (
    <>
      <Appbar
        position="fixed"
        sx={{
          backgroundColor: "#32303B",
          border: "none",
        }}
      >
        <ToolBar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Applogo src={AppLogo} alt="app-logo" />
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                padding: "4px",
                background: "#F2F2F2",
                border: "1px solid #E2E2E2",
                borderRadius: "5px",
                height: "30px",
                ml: "8px",
                "&:hover": {
                  backgroundColor: "white",
                },
                "& svg": {
                  color: "#000",
                },
              }}
              onClick={handleSidebarToggle}
            >
              {sidebarToggle ? (
                <MenuIcon src={MenuCloseIcon} />
              ) : (
                <MenuIcon src={MenuOpenIcon} />
              )}
            </IconButton>
            {screenWidth >= 768 && (
              <Box
                sx={{
                  marginLeft: "20px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                <Typography variant="h6">Signage Player</Typography>
              </Box>
            )}
          </Box>
          {screenWidth >= 768 && (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: appTheme.storeColor,
                  fontWeight: "bold",
                }}
              >
                {localStorage.getItem("store") !== null && (
                  <>{localStorage.getItem("store")}</>
                )}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              pr: screenWidth >= 768 ? "40px" : "30px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="h7"
              sx={{
                color: "white",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                setOpenMenuOutSide(true);
                handleMenu(e);
              }}
            >
              {screenWidth >= 768 && (
                <>
                  {localStorage.getItem("logged_in_user_name") !== null &&
                    localStorage.getItem("logged_in_user_name")}
                </>
              )}
            </Typography>
            <Avatar
              alt="Cindy Baker"
              id="menu-appbar"
              src={UserProfile}
              sx={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                setOpenMenuOutSide(false);
                handleMenu(e);
              }}
            />
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                marginTop: openMenuOutSide ? "10px" : null,
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {screenWidth < 768 && smallScreenMenu}
              <MenuItem onClick={handleLogout}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </ToolBar>
      </Appbar>
    </>
  );
}
