import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useCallback, useEffect, useState, useRef } from "react";
import AuthService from "./auth/AuthService";
import Link from "@mui/material/Link";
import { Box, Typography } from "@mui/material";
import config from "./config";
import { useNavigate } from "react-router-dom";
import { LoaderSection, ScreenSelectModal } from "../Components/common";
import backgroundImg from "./assets/images/background2.png";
import Message from "./common/Message";

export default function Home() {
  // const [images, setImages] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState(
    Number(localStorage.getItem("current_screen")) > 0
      ? localStorage.getItem("current_screen")
      : 0
  );
  const [loaderState, setLoaderState] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(
    Number(localStorage.getItem("current_screen")) > 0 ? false : true
  );
  // const [openPopUp, setOpenPopUp] = useState(true);
  const [selectedScreenType, setSelectedScreenType] = useState(0);
  const [currentUrlIdx, setCurrentUrlIdx] = useState(0);
  const [vidIndex, setVidIndex] = useState(0);
  const [imgIndex, setImgIndex] = useState(0);
  const [imgTimeout, setImgTimeout] = useState(5000);
  const [keepAliveTimeout, setKeepAliveTimeout] = useState(300000); // 600000 keep alive set to 10 minutes
  const [lastUpdateTime, setLastUpdateTime] = useState("");
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [isResourcesAvailble, setIsResourcesAvailble] = useState(false);

  const [allMediaFiles, setAllMediaFiles ] = useState([]);

  const handleKeyPress = useCallback((event) => {
    if (event.ctrlKey) {
      if (event.key == "b") {
        console.log(`Key pressed: ${event.key}`);
        navigate("/media");
      } else if (event.key == "i") {
        localStorage.removeItem("current_screen");
        setSelectedScreen(0);
        navigate("/loading");
      }
    }
  }, []);

  const ref = useRef(null);
  const imageRef = useRef();
  const videoRef = useRef();

  const navigate = useNavigate();
  const Auth = new AuthService();

  const [sliderRef, slider] = useKeenSlider({
    slides: allMediaFiles.length,
    loop: true,
    initial: currentUrlIdx,
    slideChanged(slider) {
      const newIdx = slider.track.details.rel;
      setCurrentUrlIdx(newIdx);
      setImgTimeout(Number(allMediaFiles[newIdx].image_timeout) * 1000);
    },
  });

  //PopupModal
  const handleScreenSelect = (event, values) => {
    setSelectedScreen(event.target.value.id);
    // setSelectedScreenType(event.target.value.screen_type);
    setOpenPopUp(false);
  };

  const isFullScreen = () => {
    return Boolean(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  };
  // Make DoFullScreen() reusable by passing the element as a parameter
  const DoFullScreen = (el) => {
    // Use a guard clause to exit out of the function immediately
    if (isFullScreen()) return false;
    // Set a default value for your element parameter
    if (el === undefined) el = document.documentElement;
    // Test for the existence of document.fullscreenEnabled instead of requestFullscreen()
    if (document.fullscreenEnabled) {
      el.requestFullscreen();
    } else if (document.webkitFullscreenEnabled) {
      el.webkitRequestFullscreen();
    } else if (document.mozFullScreenEnabled) {
      el.mozRequestFullScreen();
    } else if (document.msFullscreenEnabled) {
      el.msRequestFullscreen();
    }
  };
  const getMediaData = async() => {
        setLoaderState(true);
    Auth.fetch(`/media/media?status=1&screen_id=${selectedScreen}`).then(
      (res) => {
        // setLoaderState(false);
        if (res?.ack) {
          if (res.images.length > 0) {
            // setSelectedScreenType(res.images[0].screen_type);
            // Only store current screen when there is media associated with the screen
            localStorage.setItem("current_screen", selectedScreen);

            // setImgTimeout(Number(res.images[0].image_timeout) * 1000);

            // setKeepAliveTimeout(Number(res.images[0].image_timeout) * 1000); // need to comment this line on prod build
            // setImages(res?.images);
            const mediaData = res?.images?.map((image) => {
              const url = `${config.s3_bucket}${image.user_id}/${image.file}`;
              const media_type = image.media_type; 
              const user_id = image.user_id
              const id = image.id
              const image_timeout = image.image_timeout
              const rank = image.rank
              const screen_id = image.screen_id
              const status = image.status
              const store_id = image.store_id
              const title = image.title
              const file = image.file
              return { url, media_type, user_id, id, image_timeout, rank, screen_id, status, store_id, title, file };
            });
            downloadAndStoreFiles(mediaData);
                        
            // setIsResourcesAvailble(true);
            // setImgIndex(0);
            // setCurrentUrlIdx(0);
            // startImgTimer(res.images.length); // call first time to change the idx and trigger the timeout
            // document.documentElement.requestFullscreen();

            // DoFullScreen();
          } else {
            // setImages([]);
            setIsResourcesAvailble(false);
            setLoaderState(false);
          }
        } else {
          // setImages([]);
          setIsResourcesAvailble(false);
          localStorage.removeItem("current_screen");
          setType("info");
          setLoadMessage(res?.message);
          setLoaderState(false);

        }
      }
    );
  };


  const downloadFileAsBlob = async (url) => {
    const response = await fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
    const blob = await response.blob();
    return blob;
  };

  const storeMediaArrayInDB = async (mediaArray) => {
      await Promise.allSettled(
      mediaArray.map(async (mediaObject) => {
        const blob = await downloadFileAsBlob(mediaObject.url);
        mediaObject.fileSrc = blob;
      })
    );
  };
  
  const downloadAndStoreFiles = async (mediaArray) => {
    try {
      await storeMediaArrayInDB(mediaArray);
      setAllMediaFiles(mediaArray)
      setImgTimeout(Number(mediaArray[0].image_timeout) * 1000);
      setIsResourcesAvailble(true);
      setLoaderState(false);
      setImgIndex(0);
      setCurrentUrlIdx(0);
      DoFullScreen()
    } catch (error) {
      console.error('Error downloading or storing files:', error);
      setAllMediaFiles([])
    }
  };
  

  const getUpdatedData = () => {
    if (selectedScreen) {
      Auth.fetch(
        `/media/updated-media?screen_id=${selectedScreen}&keep_alive_timeout=${Number(keepAliveTimeout) / 1000
        }`
      ).then((res) => {
        setLoaderState(false);
        if (res?.ack) {
          // setSelectedScreenType(res.screen_type);
          setImgIndex(0);
          setCurrentUrlIdx(0);
          getMediaData();
        }
      });
    }
  };

  useEffect(() => {
    if (Number(selectedScreen) > 0) {
      getMediaData();
    }
  }, [selectedScreen]);

  useEffect(() => {
    if (allMediaFiles.length > 0) {
      if (allMediaFiles[currentUrlIdx].media_type === 1) {
        const fileSrc = allMediaFiles[currentUrlIdx].fileSrc;
        const srcUrl = fileSrc instanceof Blob
          ? URL.createObjectURL(fileSrc)
          : `${config.cloudfront_url}${allMediaFiles[currentUrlIdx].user_id}/${allMediaFiles[currentUrlIdx].file}`;
        imageRef.current.src = srcUrl;
      } else if (allMediaFiles[currentUrlIdx].media_type === 2) {
        const fileSrc = allMediaFiles[currentUrlIdx].fileSrc;
        const srcUrl = fileSrc instanceof Blob
          ? URL.createObjectURL(fileSrc)
          : `${config.cloudfront_url}${allMediaFiles[currentUrlIdx].user_id}/${allMediaFiles[currentUrlIdx].file}`;
        videoRef.current.src = srcUrl;
      }
    }
  }, [currentUrlIdx, allMediaFiles]);

  const clickHandler = (event) => {
    if (event.detail == 3) {
      localStorage.removeItem("current_screen");
      setSelectedScreen(0);
      navigate("/loading");
    }
  };

  const handleEnded = () => {
    // When video ends
    let nextIdx = (currentUrlIdx + 1) % allMediaFiles.length;
    setCurrentUrlIdx(nextIdx);
    setImgTimeout(allMediaFiles[nextIdx].image_timeout);
    // check if the next ID is of image file
    // if (images[nextIdx].media_type == 1) {
    //   // do nothing
    // }

    if (allMediaFiles[nextIdx].media_type == 2 && allMediaFiles.length == 1) {
      videoRef.current?.load();
    }
  };

  const startKeepAliveTimer = (totalImages) => {
    const timeoutId = setTimeout(() => {
      getUpdatedData();
      setLastUpdateTime(new Date());
    }, keepAliveTimeout);
  };

  const keepAlive = () => {
    getUpdatedData();
    setLastUpdateTime(new Date());
  };

  useEffect(() => {
    if (allMediaFiles?.length) {
      if (allMediaFiles[currentUrlIdx].media_type == 1) { // only set the timer if the current url index is of an image
        let image_timeout = Number(allMediaFiles[currentUrlIdx].image_timeout) * 1000;
        const timeoutId = setTimeout(() => {
          setCurrentUrlIdx((currentUrlIdx + 1) % allMediaFiles.length);
        }, image_timeout);

        return () => clearTimeout(timeoutId);
      }
    }

    return () => { };
  }, [currentUrlIdx, allMediaFiles]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      keepAlive();
    }, keepAliveTimeout);

    return () => clearTimeout(timeoutId);
  }, [lastUpdateTime]);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    // <>
    //   <LoaderSection state={loaderState} />
    //   {openPopUp ? (
    //     <ScreenSelectModal
    //       handleClose={() => setOpenPopUp(false)}
    //       open={openPopUp}
    //       handleChange={handleScreenSelect}
    //       screen={selectedScreen}
    //       loaderState={loaderState}
    //       setLoaderState={setLoaderState}
    //     />
    //   ) : (
    //     <>
    //       <Box
    //         onClick={clickHandler}
    //         sx={{
    //           width: "100vw",
    //           height: "100vh",
    //           backgroundImage: `${!isResourcesAvailble && `url(${backgroundImg})`
    //             }`,
    //           backgroundColor: `${isResourcesAvailble && "black"} `,
    //           backgroundRepeat: "no-repeat",
    //           backgroundSize: "cover",
    //         }}
    //       >
    //         {allMediaFiles.length > 0 ? (
    //           <Box
    //             className="keen-slider"
    //             sx={{
    //               width: "100vw",
    //               height: "100vh",
    //               objectFit: "fill",
    //             }}
    //           >
    //             <>
    //               {allMediaFiles[currentUrlIdx] && allMediaFiles[currentUrlIdx].media_type == 1 && (
    //                 <img
    //                   ref={imageRef}
    //                   src={
    //                     allMediaFiles[currentUrlIdx].fileSrc instanceof Blob ? 
    //                       URL.createObjectURL(allMediaFiles[currentUrlIdx].fileSrc) :
    //                       config.cloudfront_url +
    //                       allMediaFiles[currentUrlIdx].user_id +
    //                       "/" +
    //                       allMediaFiles[currentUrlIdx].file
    //                   }
    //                   alt={ "screen image"}
    //                   style={{ width: "100%", height: "100%" }}
    //                 />
    //               )}
    //               {allMediaFiles[currentUrlIdx] && allMediaFiles[currentUrlIdx].media_type == 2 && (
    //                 <video
    //                   ref={videoRef}
    //                   width="100%"
    //                   height="100%"
    //                   autoPlay
    //                   muted
    //                   onEnded={handleEnded}
    //                 >
    //                   <source
    //                     src={
    //                       allMediaFiles[currentUrlIdx].fileSrc instanceof Blob ? 
    //                       URL.createObjectURL(allMediaFiles[currentUrlIdx].fileSrc) :
    //                       config.cloudfront_url +
    //                       allMediaFiles[currentUrlIdx].user_id +
    //                       "/" +
    //                       allMediaFiles[currentUrlIdx].file
    //                     }
    //                     type="video/mp4"
    //                   />
    //                 </video>
    //               )}
    //             </>
    //           </Box>
    //         ) : (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               width: "100%",
    //               height: "100%",
    //             }}
    //           >
    //             {!loaderState &&
    //               !allMediaFiles.length && (
    //                 <Typography variant="h6">
    //                   There is no data available now!
    //                 </Typography>
    //               )}
    //           </Box>
    //         )}
    //       </Box>
    //     </>
    //   )}
    //   <Message type={type} msg={loadMessage} />
    // </>
    <>
      <LoaderSection state={loaderState} />
      {openPopUp ? (
        <ScreenSelectModal
          handleClose={() => setOpenPopUp(false)}
          open={openPopUp}
          handleChange={handleScreenSelect}
          screen={selectedScreen}
          loaderState={loaderState}
          setLoaderState={setLoaderState}
        />
      ) : (
        <div onClick={clickHandler}>
        <Box
          className="keen-slider"
          ref={sliderRef}
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `${!isResourcesAvailble && `url(${backgroundImg})`}`,
            backgroundColor: `${isResourcesAvailble && "black"}`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {allMediaFiles.length > 0 ? (
            <>
              {allMediaFiles[currentUrlIdx].media_type === 1 && (
                <img
                  ref={imageRef}
                  src={
                    allMediaFiles[currentUrlIdx].fileSrc instanceof Blob
                      ? URL.createObjectURL(allMediaFiles[currentUrlIdx].fileSrc)
                      : `${config.cloudfront_url}${allMediaFiles[currentUrlIdx].user_id}/${allMediaFiles[currentUrlIdx].file}`
                  }
                  alt="screen image"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
              {allMediaFiles[currentUrlIdx].media_type === 2 && (
                <video
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  autoPlay
                  muted
                  onEnded={handleEnded}
                >
                  <source
                    src={
                      allMediaFiles[currentUrlIdx].fileSrc instanceof Blob
                        ? URL.createObjectURL(allMediaFiles[currentUrlIdx].fileSrc)
                        : `${config.cloudfront_url}${allMediaFiles[currentUrlIdx].user_id}/${allMediaFiles[currentUrlIdx].file}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {!loaderState && (
                <Typography variant="h6">There is no data available now!</Typography>
              )}
            </Box>
          )}
        </Box>
        </div>
      )}
      <Message type={type} msg={loadMessage} />
    </>
  );
}
