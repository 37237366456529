import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
//==================SideBarDrawer theme Function =============
const openedMixin = (theme) => ({
  width: "220px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  width: "48px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "& .MuiListItemIcon-root": {
    width: "24px",
    minWidth: "24px",
  },
  "& .MuiButtonBase-root": {
    display: "flex",
    justifyContent: "center",
  },
  "& svg": {
    width: "24px",
    height: "24px",
  },
  "& .MuiListItemText-root": {
    display: "none",
  },
});
const SideBarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: "220px",
  padding: "0 12px",
  display: "flex",
  flexDirection: "column",
  minHeight: "calc(100vh - 64px)",
  // backgroundColor:'#5491b9',
  backgroundColor: "#607799",
  // backgroundColor: theme.palette.common.solidBlue,
  flexShrink: 0,
  whiteSpace: "nowrap",
  position: "relative",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    width: "100%",
  },
  "& .MuiPaper-root": {
    border: "none",
    position: "fixed",
    marginTop: "64px",
    backgroundColor: "#607799",
    // backgroundColor: theme.palette.common.solidBlue,
    height: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  "& a": {
    "&:hover": {
      textDecoration: "none",
    },
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export { SideBarDrawer };

