import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

function Loading() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    })
    return (
        <div>
            Loading ...
        </div>
    )
}

export default Loading
