import * as React from "react";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import config from "../config";
// import Image from 'next/image';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Tooltip } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from '@mui/icons-material/Edit';
import { appTheme } from "../utils";
import { Image } from "antd";
import AuthService from "../auth/AuthService";
import MediaPreviewModal from "./MediaPreviewModal";
import TestModal from "./TestModal";

const Auth = new AuthService();
const DragHandle = SortableHandle(({ style, index }) => (
  <span style={{ ...style, ...{ cursor: "move" } }}>
    <DragIndicatorIcon />
  </span>
));

const TableBodySortable = SortableContainer(({ children }) => (
  <TableBody style={{ width: "100%" }}>{children}</TableBody>
));

TableBodySortable.muiName = "TableBody";

const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px",
  },
  tblDiv: {
    width: "50%",
    float: "left",
  },
  tbl: {
    height: "360px",
    overflow: "visible",
    marginTop: "10px",
  },
  mapDiv: {
    float: "right",
    width: "48%",
  },
  tblRow: {
    cursor: "pointer",
  },
  chkBox: {
    margin: "0px",
    padding: "0px !important",
  },
}));

const CustomImage = ({ src, onThumbnailError, ...props }) => {
  const handleThumbnailError = async () => {
    if (onThumbnailError) {
      await onThumbnailError();
    }
  };

  return <Image {...props} src={src} onError={handleThumbnailError} />;
};


export default function DraggableTable(props) {
  const classes = useStyles();
  const [images, setImages] = React.useState(props.images);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const navigate = useNavigate();
  React.useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  // Handler for traversing completion, helper arrayMove is used
  const onSortEnd = ({ oldIndex, newIndex, rIdx }) => {
    const images1 = arrayMove(images, oldIndex, newIndex);
    setImages(images1);
    props.afterSort(images1);
  };

  const handleRowClick = (id) => {
    navigate("/media/" + id)
  };

  const generateThumbnail = async (key, name) => {
    try {
      const response = await Auth.fetch(`/generate-thumbnail?bucket=prefectos-signage&key=${key}/${name}`);
      if (response) {
        if (response?.ack) {
          console.log(response);
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [modalData, setModalData] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

const handleRowClickMedia = (data) => {
  const mediaItems = images.filter(item => item.user_id === data.user_id);
  const index = mediaItems.findIndex(item => item.id === data.id);
  setCurrentIndex(index);
  setModalData(data);
  setModalOpen(true);
};

const handleCloseModal = () => {
  setModalOpen(false);
  setCurrentIndex(0);

};

  const Row = SortableElement(({ data, idx }) => {
    console.log('filename',data.file)
    return (
      <>
        <TableRow
          className={classes.tblRow}
          style={idx % 2 ? { background: "#D3D3D3" } : { background: "white" }}
          key={data.id}
        >
          <TableCell style={{ padding: "3px", width: "20px" }}>
            <DragHandle index={idx} />
          </TableCell>
          <TableCell style={{ width: "20px" }}>{data.rank}</TableCell>
          <TableCell>{data.title}</TableCell>
          <TableCell>{data.file.split("---")[0] + "." + data.file.split('.').pop()}</TableCell>
          <TableCell>{data.media_type == 1 ? data.image_timeout : ""}</TableCell>

          <TableCell onClick={()=> handleRowClickMedia(data)}>
            {data.media_type == 1 && (
              <CustomImage
              style={{ width: "75px", height: "50px" }}
              src={config.cloudfront_url_thumbnails + data.user_id + "/" + data.file}
              preview={{
                src: `${config.cloudfront_url_thumbnails + data.user_id + "/" + data.file}`,
              }}
              onThumbnailError={() => generateThumbnail(data.user_id, data.file)}
            />
            )}


            {data.media_type == 2 && <PlayCircleOutlineIcon />}
          </TableCell>
          <TableCell>{data.status === 1 ? "Yes" : "No"}</TableCell>
          <TableCell align="right" width={80}>
            {Number(data.status) === 1 && (
              <Tooltip title="Hide">
                <VisibilityOffIcon
                  onClick={() => {
                    props.handleMediaStatus(data, 0);
                  }}
                />
              </Tooltip>
            )}
            {data.status === 0 && (
              <Tooltip title="Show">
                <VisibilityIcon
                  onClick={() => {
                    props.handleMediaStatus(data, 1);
                  }}
                />
              </Tooltip>
            )}
            <EditIcon
              className={classes.actionBtn}
              onClick={() => {
                handleRowClick(data.id);
              }}
            />

            <DeleteIcon
              className={classes.actionBtn}
              onClick={() => {
                props.deleteMedia(data);
              }}
            />

          </TableCell>
        </TableRow>
      </>
    );
  });

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: "15px",
        }}
      >
        <Table
          sx={{ width: "100%", border: "2px solid #b0aea9" }}
          aria-label="caption table"
        >
          <caption>{`List of Images - Total Items - ${images?.length}`}</caption>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Position</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Screen</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>File Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Timeout</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Media</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Show</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBodySortable
            onSortEnd={onSortEnd}
            useDragHandle
            displayRowCheckbox={false}
          >
            {images.length > 0 &&
              images.map((row, index) => {
                return (
                  <Row index={index} idx={index + 1} key={row.id} data={row} />
                );
              })}
          </TableBodySortable>
        </Table>
      </TableContainer>
      {modalData && (
      <TestModal
        open={modalOpen}
        handleClose={handleCloseModal}
        data={modalData}
        allMedia={images}
        currentIndex={currentIndex}
      />
    )}
    </>
  );
}
