import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const LoaderSection = ({ state }) => {
  return (
    state && <Box sx={{
      position:"fixed",
      top:"0",
      left:"0",
      width:"100vw",
      height:"100vh",
      zIndex:"100000",
      opacity:".5",
      backgroundColor:"#ffffffac",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    }} >
   
   <CircularProgress disableShrink />
    </Box>
  )
}

export default LoaderSection
