import React, { useEffect, useState } from "react";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import AuthService from "./../auth/AuthService";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Message from "./../common/Message";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import config from "../config";
import { makeStyles } from "@mui/styles";
import { Link as NavLink } from "react-router-dom";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://prefectos.com">
        Prefectos
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const initialFormData = Object.freeze({
  firstName: "",
  lastName: "",
  store: "",
  franchisor: "",
  email: "",
  password: "",
  subscribe: "",
});

const useStyles = makeStyles((theme) => ({
}));
export default function SignUp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [formData, updateFormData] = React.useState(initialFormData);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("success");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [storeError, setStoreError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [franchisors, setFranchisors] = React.useState([]);
  const Auth = new AuthService();
  const isAuthenticated = Auth.loggedIn();
  useEffect(() => {

    if (isAuthenticated) return navigate("/");

    fetch(`${config.domain}/api/auth/franchisors`)
      .then((res) => res.json())
      .then((res) => {
        if (res.ack) {
          setFranchisors(res.franchisors)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value
    });
  };

  const validateRegister = (formData) => {
    let flg = true;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (formData.firstName === "") {
      setFirstNameError("Required");
      flg = false;
    } else {
      setFirstNameError("");
    }

    if (formData.lastName === "") {
      setLastNameError("Required");
      flg = false;
    } else {
      setLastNameError("");
    }

    // if (formData.store === "") {
    //   setStoreError("Required");
    //   flg = false;
    // } else {
    //   setStoreError("");
    // }

    if (formData.email === "") {
      setEmailError("Required");
      flg = false;
    } else if (!emailRegex.test(formData.email)) {
      setEmailError("Invalid email");
      flg = false;
    } else {
      setEmailError("");
    }

    if (formData.password === "") {
      setPasswordError("Required");
      flg = false;
    }
    // else if (!passwordRegex.test(formData.password)) {
    //   //     // At least one digit [0-9]
    //   //     // At least one lowercase character [a-z]
    //   //     // At least one uppercase character [A-Z]
    //   //     // At least one special character [*.!@#$%^&(){}[]:;<>,.?/~_+-=|\]
    //   //     // At least 8 characters in length, but no more than 32.
    //   setPasswordError(
    //     "Invalid password must be minimum eight characters, at least one letter and one number"
    //   );
    //   flg = false;
    // } 
    else {
      setPasswordError("");
    }
    return flg;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateRegister(formData)) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };
      fetch(`${config.domain}/api/auth/register`, requestOptions)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.ack) {
              setType("success");
              setLoadMessage(result.message);

              setTimeout(() => {
                window.location = "/";
              }, 2000);
            } else {
              setType("error");
              setLoadMessage(result.message);
              setError(result.status);
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        );
    } else return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <form className={classes.form} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    error={firstNameError === "" ? false : true}
                    helperText={firstNameError}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    error={lastNameError === "" ? false : true}
                    helperText={lastNameError}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="store"
                    label="Store"
                    name="store"
                    autoComplete="store"
                    error={storeError === "" ? false : true}
                    helperText={storeError}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid> */}
     <Grid item xs={12}>
     <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="franchisor-label">Frachisor</InputLabel>
                  <Select
                    size="small"
                    name="franchisor"
                    labelId="franchisors-label"
                    id="franchisor"
                    label="Franchisor"
                    onChange={handleChange}
                  >
                    {franchisors.map((ss) => {
                      return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

     </Grid>
              

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={emailError === "" ? false : true}
                    helperText={emailError}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={passwordError === "" ? false : true}
                    helperText={passwordError}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
              <Grid className={classes.textCenter}>
                <Grid item>
                  <NavLink to="/login" variant="body2" className={classes.linkColor}>
                    Already have an account? Sign in
                  </NavLink>
                </Grid>
              </Grid>
            </form>

            <Message type={type} msg={loadMessage} />
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}