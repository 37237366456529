import * as React from "react";

import { Box, Button, Grid, Pagination, TextField } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import AuthService from "../auth/AuthService";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Link from "@mui/material/Link";
import Message from "../common/Message";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as NavLink } from "react-router-dom";
import { statuses } from "../common/constants";
import { confirmationModal } from "../utils";
import { appTheme } from "../utils";
import { LoaderSection } from "../common";
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    float: "left",
  },
  addIcon: {
    float: "right",
  },
}));

const Auth = new AuthService();

export default function StoreList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [stores, setStores] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(50);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    Auth.post(`/store/stores`, {
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        setLoading(false);
        if (res.ack) {
          setStores(res.stores);
          setTotalPages(Math.ceil(res.total_count / limit));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate("/store/" + id);
  };

  const Search = () => {
    setLoading(true);
    Auth.post(`/store/stores`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        setLoading(false);
        if (res.ack) {
          setStores(res.stores);
          setTotalPages(Math.ceil(res.total_count / limit));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const confirmToDelete = (id) => {
    const params = {
      method: "DELETE",
      body: JSON.stringify({
        id: id,
      }),
    };
    // setLoading(true);
    setLoadMessage("");
    Auth.fetch(`/store/store`, params)
      .then((res) => {
        if (res.ack) {
          setType("info");
          setLoadMessage(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // setLoading(false);
        } else {
          setType("error");
          setLoadMessage(res.message);
          // setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (id) => {
    confirmationModal(() => {
      confirmToDelete(id);
    });
  };

  return (
    <div>
      <LoaderSection state={loading} />
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-stores"}}>Import Stores</Button> */}
      {/* <div className={classes.tblTopDiv}> */}
      {/* <div className={classes.tblsearch}> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            style={
              {
                // float: "right",
                // width: "350px",
                // height: "10px",
                // marginRight: "5px",
              }
            }
            size="small"
            id="standard-adornment-password"
            type="text"
            label="Search"
            variant="outlined"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton aria-label="Search By Student Name" onClick={Search}>
            //         <SearchIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          />
          <Button
            // style={{ height: "55px" }}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: appTheme.btnColor,
              "&:hover": {
                backgroundColor: appTheme.btnColor, // Change the color on hover
              },
            }}
            onClick={Search}
            endIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Box>
        <NavLink to="/store/new">
          <AddCircleIcon
            fontSize="large"
            style={{
              color: appTheme.btnColor,
            }}
          />
        </NavLink>
      </Box>
  
      {/* </div> */}
      {/* <div className={classes.addIcon}> */}

      {/* </div> */}
      {/* </div> */}
      <TableContainer
        component={Paper}
        sx={{
          marginTop: "15px",
        }}
      >
        <Table
          sx={{ width: "100%", border: "2px solid #b0aea9" }}
          aria-label="caption table"
        >
          <caption>List of Stores</caption>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Title</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Frachisor</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stores.map((row, index) => (
              <TableRow
                style={
                  index % 2
                    ? { background: "#D3D3D3" }
                    : { background: "white" }
                }
                key={row.id}
                className={classes.row}
              >
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.frachise_name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>
                  {row.status != null
                    ? statuses.filter((obj) => obj.id == row.status)[0].title
                    : ""}
                </TableCell>

                <TableCell width={50} align="center">
                  <EditIcon
                    className={classes.actionBtn}
                    onClick={() => {
                      handleRowClick(row.id);
                    }}
                  />
                  <DeleteIcon
                    className={classes.actionBtn}
                    onClick={() => {
                      handleDelete(row.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Message type={type} msg={loadMessage} />
      </TableContainer>
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
