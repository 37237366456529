import { Route } from "react-router-dom";
import Layout from "../Components/Layout";
import ProtectedRoute from "./ProtectedRoute";
import Home from "../Components/Home";
import About from "../Components/About";
import Contact from "../Components/Contact";
import ScreenList from "../Components/Screens/ScreenList";
import Screen from "../Components/Screens/Screen";
import MediaList from "../Components/Media/MediaList";
import Media from "../Components/Media/Media";
import Setup from "../Components/Setup/Setup";
import User from "../Components/Users/User";
import UserList from "../Components/Users/UserList";
import Store from "../Components/Stores/Store";
import StoreList from "../Components/Stores/StoreList";

const Web = [
  { id: 1, path: "/screens", elemet: <ScreenList /> },
  { id: 2, path: "/screen/:id", elemet: <Screen /> },
  { id: 3, path: "/media", elemet: <MediaList /> },
  { id: 4, path: "/media/:id", elemet: <Media /> },
  { id: 5, path: "/users", elemet: <UserList /> },
  { id: 6, path: "/user/:id", elemet: <User /> },
  { id: 7, path: "/stores", elemet: <StoreList /> },
  { id: 8, path: "/store/:id", elemet: <Store /> },
  { id: 9, path: "/setup", elemet: <Setup /> },
];

export const WebRoutes = () => {
  return Web.map((item, index) => {
    return (
      <Route
        key={index}
        path={item.path}
        element={
          <ProtectedRoute>
            <Layout>{item.elemet}</Layout>
          </ProtectedRoute>
        }
      />
    );
  });
};
