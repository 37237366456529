import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import AuthService from "../auth/AuthService";
import backgroundImg from "../assets/images/background2.png";
import CloseIcon from "@mui/icons-material/Close";
import { LoaderSection } from "../common";
export default function ScreenSelectModal({
  handleClose,
  open,
  handleChange,
  screen,
  loaderState,
  setLoaderState,
}) {
  const Auth = new AuthService();
  const [screens, setScreens] = useState([]);

  const getScreens = () => {
    setLoaderState(true);
    Auth.fetch(`/screen/screens`)
      .then((res) => {
        if (res.ack) {
          setScreens(res.screens);
          setLoaderState(false);
        }
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getScreens();
  }, []);

  return (
    <Dialog
      open={open}
      sx={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {!loaderState && (
        <>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Screen</span>

            {/* <span><CloseIcon 
      onClick={handleClose}
    
      sx={{
        cursor:"pointer"
      }}/></span> */}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {/* {
                screens.length === 0 
                "You have no screens at the moment, please contact us at support@prefectos.com"}
              {screens.length > 0 &&
                "Please select the Screen and then proceed !"} */}
                {
                 !loaderState && screens?.length > 0 ? "Please select the Screen and then proceed !" : "You have no screens at the moment, please contact us at support@prefectos.com" 
                }
            </DialogContentText>
          </DialogContent>
          <Box
            sx={{
              paddingX: "20px",
              paddingBottom: "20px",
            }}
          >
            {screens.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Screen</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="screen"
                  value={screen}
                  label="screen"
                  onChange={handleChange}
                >
                  {screens.map((screen, idx) => {
                    return (
                      <MenuItem key={idx} value={screen}>
                        {screen.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Box>
        </>
       )}
    </Dialog>
  );
}
