import * as React from "react";

import { Button, Grid, TextField } from "@mui/material";
import AuthService from "../auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../common/Message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { screenTypes, statuses } from "../common/constants";
import { appTheme } from "../utils";
import { LoaderSection } from "../common";
const Auth = new AuthService();
export default function Screen(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [title, setTitle] = React.useState("");
  const [imageTimeout, setImageTimeout] = React.useState(10);
  const [titleError, setTitleError] = React.useState("");
  const [imageTimeoutError, setImageTimeoutError] = React.useState("");
  const [screenType, setScreenType] = React.useState({ id: "", title: "" });
  const [screenStatus, setScreenStatus] = React.useState({
    id: "1",
    title: "Active",
  });
  const [screenStatusError, setScreenStatusError] = React.useState("");
  const [stores, setStores] = React.useState([]);
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [selectedStoresError, setSelectedStoresError] = React.useState("");
  const [screenTypeError, setScreenTypeError] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (id > 0) {
      setLoading(true);
    }
    Auth.fetch(`/store/stores`)
      .then((res1) => {
        const stores1 = res1.stores;
        setStores(res1.stores);
        if (id > 0) {
          Auth.fetch(`/screen/screen?id=${id}`)
            .then((res) => {
              setLoading(false);
              if (res.ack) {
                setTitle(res.screen?.title);
                setImageTimeout(res.screen.image_timeout);
                // const screenType1 = screenTypes.find(
                //   (obj) => obj.id == res.screen.screen_type
                // );
                // setScreenType(screenType1);
                const screenStatus = statuses.find(
                  (obj) => obj?.id == res.screen.screen_status
                );
                setScreenStatus(screenStatus);

                const selected_stores1 = res.selected_stores;
                const selected_stores = [];

                if (selected_stores1.length > 0) {
                  selected_stores1.map((st) => {
                    let d = stores1.find((obj) => obj.id == st.store_id);
                    if (d.id) {
                      selected_stores.push(d);
                    }
                  });
                  console.log(selected_stores);
                  setSelectedStores(selected_stores);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const onClickCancel = () => {
    navigate("/screens");
  };

  const onChangeScreenType = (e, values) => {
    setScreenType(values);
  };

  const onChangeScreenStatus = (e, values) => {
    setScreenStatus(values);
  };

  const onChangeStore = (event, values) => {
    setSelectedStores(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }
    // if (
    //   screenType == undefined ||
    //   screenType.id == undefined ||
    //   screenType.id == ""
    // ) {
    //   setScreenTypeError("Required");
    //   return;
    // } else {
    //   setScreenTypeError("");
    // }
    
    if (Number(imageTimeout) < 5) {
      setImageTimeoutError("Minimum value must be 5 or greater than 5.");
      return;
    } else {
      setImageTimeoutError("");
    }

    // if (screenType.id === 2 && Number(imageTimeout) === 0) {
    //   setImageTimeoutError("Required");
    //   return;
    // } else {
    //   setImageTimeoutError("");
    // }

    if (selectedStores.length === 0) {
      setSelectedStoresError("Required");
      return;
    } else {
      setSelectedStoresError("");
    }
    if (screenStatus === null || screenStatus === undefined) {
      setScreenStatusError("Please select screen status");
      return;
    } else {
      setScreenStatusError("");
    }

    if (id > 0) {
      setLoading(true);
      Auth.fetch("/screen/screen", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          // screen_type: screenType.id,
          screen_status: screenStatus.id,
          image_timeout: imageTimeout,
          selected_stores: selectedStores,
          id: id,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/screens");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      Auth.fetch("/screen/screen", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          // screen_type: screenType.id,
          screen_status: screenStatus?.id,
          image_timeout: imageTimeout,
          selected_stores: selectedStores,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/screens");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <LoaderSection state={loading} />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> {id > 0 ? "Update" : "Add"} Screen</h1>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={11} sm={6} md={5} lg={4} xl={3}>
            <TextField
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              label="Title"
              id="title"
              size="small"
              error={titleError === "" ? false : true}
              helperText={titleError}
              // inputProps={{ min: 5}}
            />
            <br />
            <TextField
              size="small"
              type="number"
              value={imageTimeout}
              onChange={(event) => {
                if (event.target.value < 5) {
                  setImageTimeoutError(
                    "Minimum value must be 5 or greater than 5."
                  );
                  setImageTimeout(event.target.value);
                } else {
                  setImageTimeoutError("");
                  setImageTimeout(event.target.value);
                }
              }}
              label="Image Timeout"
              id="image_timeout"
              error={imageTimeoutError === "" ? false : true}
              helperText={imageTimeoutError}
            />
            {/* 
            <br />
            <Autocomplete
              id="tags-standard"
              size="small"
              options={screenTypes}
              getOptionLabel={(option) => option.title}
              value={screenType}
              onChange={onChangeScreenType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Screen Type"
                  placeholder="Screen Type"
                  error={screenTypeError === "" ? false : true}
                  helperText={screenTypeError}
                />
              )}
            /> */}

            <br />
            {stores.length > 0 && (
              <Autocomplete
                multiple
                id="stores"
                options={stores}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                value={selectedStores}
                onChange={onChangeStore}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={selectedStoresError === "" ? false : true}
                    variant="standard"
                    label="Stores"
                    placeholder="Stores"
                    helperText={selectedStoresError}
                  />
                )}
              />
            )}

            <br />
            <Autocomplete
              id="status"
              size="small"
              options={statuses}
              getOptionLabel={(option) => option.title}
              value={screenStatus}
              onChange={onChangeScreenStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={screenStatusError === "" ? false : true}
                  variant="standard"
                  label="Screen Status"
                  placeholder="Screen Status"
                  helperText={screenStatusError}
                />
              )}
            />
            <br />
          </Grid>
        </Grid>
        <Box style={{ float: "right", marginTop: "40px" }}>
          <Button
            variant="contained"
            sx={{
              marginRight: "10px",
              backgroundColor: "#32303B",
              "&:hover": {
                backgroundColor: "#32303B", // Change the color on hover
              },
            }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: appTheme.btnColor,
              "&:hover": {
                backgroundColor: appTheme.btnColor, // Change the color on hover
              },
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>

        <Message type={type} msg={loadMessage} />
      </Box>
    </>
  );
}
