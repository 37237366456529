import { Navigate, Outlet } from 'react-router-dom';

import AuthService from "../Components/auth/AuthService";
import React from "react";
import {Route} from "react-router-dom"

const Auth = new AuthService();

const ProtectedRoute = ({children}) => {  
    const isAuthenticated = Auth.loggedIn();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? children : <Navigate to="/login" />;;
}

export default ProtectedRoute;