import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import config from "../config";

import { Button, Carousel } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  // height: '80vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

export default function TestModal({ open, handleClose, data, allMedia, currentIndex }) {
  const mediaItems = allMedia.filter(item => item.user_id === data.user_id);

  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <RightCircleOutlined
        className={className}
        // style={{ ...style, display: 'block', fontSize: 50, }}
        style={{
          display: 'block',
          fontSize: 50,
          position: 'absolute',
          right: '-30px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
    )
  }
  
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <LeftCircleOutlined
        className={className}
        // style={{ ...style,  display: 'block', fontSize: 50}}
        style={{
          display: 'block',
          fontSize: 50,
          position: 'absolute',
          left: '-60px', 
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
    )
  }

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Carousel 
        dots={false}
        arrows
        {...settings}

        style={{ height: '100%', background: 'black' }}
        initialSlide={currentIndex}
        key={data ? `carousel-${data.id}` : 'carousel'}
      >
        {mediaItems.map((item, index) => (
          item.media_type === 1 ? (
            <div key={index} style={{ height: '100%' }}>
              <img
                src={config.cloudfront_url + item.user_id + "/" + item.file}
                alt={item.title}
                style={{ maxHeight: "90%", maxWidth: "100%" }}
              />
            </div>
          ) : (
            item.media_type === 2 && (
              <div key={index} style={{ height: '100%' }}>
                <video
                  controls
                  muted
                  style={{ maxHeight: "90%", maxWidth: "100%" }}
                >
                  <source
                    src={config.cloudfront_url + item.user_id + "/" + item.file}
                    type="video/mp4"
                  />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            )
          )
        ))}
      </Carousel>
        </Box>
      </Modal>
    </div>
  );
}
