import { Fade, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import config from "../config";
const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
}));
const PreviewModal = ({ open, handleClose, data }) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      // closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500
      // }}
    >
      <Fade in={open} timeout={500} className={classes.img}>
        {data.media_type === 1 ? (
          <img
            src={config.cloudfront_url + data.user_id + "/" + data.file}
            alt="asd"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        ) : (
          data.media_type === 2 && (
            <video
              controls
              style={{ maxHeight: "90%", maxWidth: "90%" }}
              autoPlay
              muted
            >
              <source
                src={config.cloudfront_url + data.user_id + "/" + data.file}
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )
        )}
      </Fade>
    </Modal>
  );
};

export default PreviewModal;
