import * as React from "react";

import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AuthService from "../auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import Message from "../common/Message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import config from "../config";
import qs from "query-string";
import { confirmationModal, appTheme } from "../utils";
import { LoaderSection } from "../common";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";


const Auth = new AuthService();
export default function Media(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [screens, setScreens] = React.useState([]);
  const [file, setFile] = React.useState("");
  const [mediaType, setMediaType] = React.useState("");
  const [imageTimeout, setImageTimeout] = React.useState("");

  const [stores, setStores] = React.useState([]);
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [screen, setScreen] = React.useState({ id: "", title: "" });
  const [screenError, setScreenError] = React.useState("");
  const [putURLs, setPutURLs] = React.useState([]);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [slectedStoreError, setSlectedStoreError] = React.useState("");

  React.useEffect(() => {
    if (id > 0) {
      Auth.fetch(`/media/media-id?id=${id}`)
        .then((res) => {
          let media = res.media[0];
          setFile(media.user_id + "/" + media.file);
          setImageTimeout(media.image_timeout);
          setMediaType(media.media_type);
          if (res.ack) {
            Auth.fetch("/screen/screens").then((res1) => {
              if (res1.ack) {
                let screens1 = res1.screens;
                setScreens(screens1);
                let screen1 = screens1.filter((s) => s.id == media.screen_id);
                if (screen1.length) {
                  setScreen(screen1[0]);
                }

                Auth.fetch(
                  "/screen/screens-stores?screen_id=" + screen1[0]?.id
                ).then((res2) => {
                  if (res.ack) {
                    const stores = res2.stores;
                    setStores(stores);
                    const selected_stores = [];

                    let storesArr = media.stores.split(",");
                    if (stores.length > 0) {
                      storesArr.map((st) => {
                        let st1 = stores.filter((s) => s.id == st);
                        if (st1.length) selected_stores.push(st1[0]);
                      });
                      setSelectedStores(selected_stores);
                    }
                  } else {
                    setStores([]);
                    setSelectedStores([]);
                  }
                });
              } else {
                setScreens([]);
              }
            });
            // const vehicleType1 = vehicleTypes.find(obj => obj.id == res.media.vehicle_type);
            // setVehicleType(vehicleType1);

            // const selected_days1 = res.seletected_days;
            // const selected_days = [];

            // if (selected_days1.length > 0) {
            //   selected_days1.map((day) => {
            //     let d = availableDays.find(obj => obj.id == day.day);
            //     if (d.id) {
            //       selected_days.push(d)
            //     }
            //   });
            //   setSelectedDays(selected_days);
            //   console.log(selected_days);
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      Auth.fetch("/screen/screens").then((res) => {
        setLoading(false);
        if (res.ack) {
          setScreens(res.screens);
        } else {
          setScreens([]);
        }
      });
    }
  }, []);

  const onClickCancel = () => {
    navigate("/media");
  };

  // const [screen, setScreen] = React.useState({ id: "", title: "" });

  const formValidation = () => {
    let errorMessage = "";
    let isTimewrong = false;
    let isTimeoutMissing = false;
    putURLs.forEach((item) => {
      if (item.type === 1) {
        if (item.image_timeout < 5) {
          isTimewrong = true;
        }
        if (item.image_timeout === undefined || item.image_timeout < 0) {
          isTimeoutMissing = true;
        }
      }
    });

    if (screen === null || screen?.id === "" || screen?.title === "") {
      errorMessage = "Please select screen";
    } else if (selectedStores.length === 0) {
      errorMessage = "Please select the store";
    } else if (id == 0 && putURLs.length === 0) {
      errorMessage = "Please upload the file";
    } else if (isTimewrong) {
      errorMessage = "Please enter valid timeout for media files.";
    } else if (isTimeoutMissing) {
      errorMessage = "Please enter timeout for media files.";
    } else if (id > 0 && mediaType == 1 && imageTimeout < 5) {
      errorMessage = "Please enter valid timeout for media files.";
    }

    return errorMessage;
  };

  const onChangeStore = (event, values) => {
    setSelectedStores(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorMessage = formValidation();
    if (errorMessage !== "") {
      setType("error");
      setLoadMessage(errorMessage);
      if (screen === null || screen?.id === "") {
        setScreenError("Required");
      } else {
        setScreenError("");
      }
      if (selectedStores.length === 0) {
        setSlectedStoreError("Required");
      } else {
        setSlectedStoreError("");
      }
      return;
    } else {
      setSlectedStoreError("");
      setScreenError("");
      setLoading(true);
      let images = [];
      putURLs.forEach((p) => {
        images.push({
          name: p.name,
          media_type: p.type,
          image_timeout: p.image_timeout,
        });
      });
  
      if (id > 0) {
        // Update existing media
        try {
          const res = await Auth.fetch("/media/media", {
            method: "PUT",
            body: JSON.stringify({
              image_timeout: imageTimeout,
              selected_stores: selectedStores,
              screen_id: screen?.id,
              id: id,
            }),
          });
  
          if (res.ack) {
            setType("success");
            await Auth.fetch(`/media/media-sort-all-stores`, {
              method: "PUT",
              body: JSON.stringify({ screen_id: screen?.id }),
            });
            setTimeout(() => navigate("/media"), 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        // Save new media
        try {
          const res = await Auth.fetch("/media/save-media", {
            method: "POST",
            body: JSON.stringify({
              images: images,
              selected_stores: selectedStores,
              screen_id: screen?.id,
            }),
          });
  
          // Sequential upload of files
          for (let f of putURLs) {
            try {
              const data = await fetch(f.url, {
                method: "PUT",
                body: f.file,
                headers: {
                  "Content-Type": f.ContentType,
                },
              });
  
              if (data.status === 200) {
                console.log("File uploaded successfully", data);
              } else {
                console.error("Failed to upload file:", f.name);
              }
            } catch (error) {
              console.error("Error during file upload:", error);
              break; // Stop if one upload fails
            }
          }
  
          setLoading(false);
          setType("success");
          setLoadMessage("File(s) uploaded successfully");
          navigate("/loading");
  
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };
  
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let errorMessage = formValidation();
  //   if (errorMessage !== "") {
  //     setType("error");
  //     setLoadMessage(errorMessage);
  //     if (screen === null || screen?.id === "") {
  //       setScreenError("Required");
  //     } else {
  //       setScreenError("");
  //     }
  //     if (selectedStores.length === 0) {
  //       setSlectedStoreError("Required");
  //     } else {
  //       setSlectedStoreError("");
  //     }
  //     return;
  //   } else {
  //     setSlectedStoreError("");
  //     setScreenError("");
  //     setLoading(true);
  //     let images = [];
  //     putURLs.map((p) => {
  //       images.push({
  //         name: p.name,
  //         media_type: p.type,
  //         image_timeout: p.image_timeout,
  //       });
  //     });

  //     if (id > 0) {
  //       Auth.fetch("/media/media", {
  //         method: "PUT",
  //         body: JSON.stringify({
  //           image_timeout: imageTimeout,
  //           selected_stores: selectedStores,
  //           screen_id: screen?.id,
  //           id: id,
  //         }),
  //       })
  //         .then((res) => {
  //           if (res.ack === true) {
  //             setType("success");
  //             Auth.fetch(`/media/media-sort-all-stores`, {
  //               method: "PUT",
  //               body: JSON.stringify({
  //                 screen_id: screen?.id,
  //               }),
  //             })
  //               .then((res1) => {
  //                 setTimeout(() => {
  //                   navigate("/media");
  //                 }, 1000);
  //               })
  //               .catch((error) => {
  //                 console.error("Error:", error);
  //               });
  //           } else {
  //             setType("error");
  //             setLoadMessage(res.message);
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //         });
  //     } else {
  //       Auth.fetch("/media/save-media", {
  //         method: "POST",
  //         body: JSON.stringify({
  //           images: images,
  //           selected_stores: selectedStores,
  //           screen_id: screen?.id,
  //         }),
  //       })
  //         .then((res) => {
  //           let uploadPromises = [];
  //           putURLs.map((f, idx) => {
  //             let promise = fetch(f.url, {
  //               method: "put",
  //               body: f.file,
  //               headers: {
  //                 "Content-Type": f.ContentType,
  //               },
  //             })
  //               .then((data) => {
  //                 if (data.status === 200) {
  //                   console.log("data", data);
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.log("error", error);
  //               });
  //             uploadPromises.push(promise);
  //           });
  //           Promise.all(uploadPromises)
  //             .then((responses) => {
  //               setLoading(false);
  //               setType("success");
  //               console.log("responses", responses);
  //               setLoadMessage("File(s) uploaded successfully");
  //               navigate("/loading");
  //             })
  //             .catch((error) => {
  //               console.error("Error uploading files:", error);
  //             });
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //         });
  //     }
  //   }
    
  // };
  const onChangeScreen = (event, values) => {
    setScreen(values);
    setLoading(true);

    Auth.fetch("/screen/screens-stores?screen_id=" + values?.id).then((res) => {
      setLoading(false);
      if (res.ack) {
        const stores = res.stores;
        setStores(stores);
        const selected_stores = [];

        if (stores.length > 0) {
          stores.map((st) => {
            selected_stores.push(st);
          });
          setSelectedStores(selected_stores);
        }
      } else {
        setStores([]);
        setSelectedStores([]);
      }
    });
  };

  const handleSubmitImage = (e) => {
    e.preventDefault();
    setLoading(true);
    let acceptedFiles = e.target.files;
    let uploadPromises = [];
    for (let idx = 0; idx < acceptedFiles.length; idx++) {
      var fileExt = acceptedFiles[idx].name.split(".").pop();

      let temp = Math.random().toString(36).slice(5);
      let file_name =
        acceptedFiles[idx].name
          .substring(0, 200)
          .split(".")
          .slice(0, -1)
          .join(".") +
        "---" +
        temp +
        "." +
        fileExt; // uuidv4();
      let params = {
        bucket: config.aws_bucket,
        ContentType: acceptedFiles[idx].type,
        Key: file_name,
      };
      let promise = Auth.fetch(`/generate-put-url?${qs.stringify(params)}`, {
        headers: {
          "Content-Type": params.ContentType,
        },
      })
        .then((res) => {
          let putURLS1 = putURLs;
          putURLS1.push({
            name: file_name,
            display_name:
              file_name.split("---")[0] + "." + file_name.split(".").pop(),
            image_timeout: screen.image_timeout,
            file: acceptedFiles[idx],
            url: res.putURL,
            type: acceptedFiles[idx].type.startsWith("image") ? 1 : 2,
            ContentType: acceptedFiles[idx].type,
          });
          setPutURLs([...putURLS1]);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      uploadPromises.push(promise);
    }
    Promise.all(uploadPromises)
      .then((responses) => {
        console.log("responses ok Promise all", responses);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const confirmToDelete = (index) => {
    setPutURLs(putURLs.filter((i, ind) => ind !== index));
  };
  const deleteImage = (index) => {
    confirmationModal(() => {
      confirmToDelete(index);
    });
  };

  const onChangeImageTimeout = (e, index) => {
    let putURLS1 = [...putURLs];
    putURLS1[index].image_timeout = e.target.value;
    setPutURLs(putURLS1);
  };

  console.log('urls', putURLs);

  return (
    <>
      <LoaderSection state={loading} />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> {id > 0 ? "Update" : "Add"} Media</h1>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {screens.length > 0 && (
              <Autocomplete
                id="tags-standard"
                size="small"
                options={screens}
                getOptionLabel={(option) => option.title}
                value={screen}
                disabled={id > 0 ? true : false}
                onChange={onChangeScreen}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Screen"
                    placeholder="Screen"
                    error={screenError === "" ? false : true}
                    helperText={screenError}
                  />
                )}
              />
            )}
            <br />
            {stores.length > 0 && (
              <Autocomplete
                multiple
                id="stores"
                options={stores}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                filterSelectedOptions
                value={selectedStores}
                onChange={onChangeStore}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={slectedStoreError === "" ? false : true}
                    variant="standard"
                    label="Stores"
                    placeholder="Stores"
                    helperText={slectedStoreError}
                  />
                )}
              />
            )}
            <br />
            {id > 0 && mediaType == 1 && file && (
              <>
                <TextField
                  type="number"
                  value={imageTimeout}
                  error={imageTimeout < 5}
                  variant="standard"
                  onChange={(e) => {
                    setImageTimeout(e.target.value);
                  }}
                />
                {imageTimeout < 5 && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    Timeout must be 5 or greater.{" "}
                  </p>
                )}
                <img src={config.cloudfront_url + file} style={{ width: "100%" }} />
              </>
            )}


            {id > 0 && mediaType == 2 && file && (<video
              width="100%"
              height="100%"
              controls
              autoPlay
              muted
            >
              <source
                src={config.cloudfront_url + file}
                type="video/mp4"
              />
            </video>)
            }
            {id == "new" &&
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: appTheme.btnColor,
                  "&:hover": {
                    backgroundColor: appTheme.btnColor, // Change the color on hover
                  },
                }}
              >
                Upload File
                <input
                  type="file"
                  accept="image/*,video/*"
                  multiple
                  hidden
                  onChange={handleSubmitImage}
                />
              </Button>
            }
          </Grid>
        </Grid>

        <Box
          sx={{
            maxWidth: "400px",
          }}
        >
          {putURLs.length > 0 && (
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "15px",
                border: "1.5px solid #b0aea9",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Thumbnails</TableCell>
                    <TableCell>Timeout (seconds)</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {putURLs.length > 0 &&
                    putURLs.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.display_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            row.type == 1 ? (
                              <img
                        style={{ width: "75px", height: "50px" }}
                        src={URL.createObjectURL(row.file)}
                        
                        />
                            ) : (
                              <PlayCircleOutlineIcon />
                            )
                          }
                        
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.type == 1 ? (
                            <TextField
                              type="number"
                              value={row.image_timeout}
                              error={row.image_timeout < 5}
                              variant="standard"
                              onChange={(e) => {
                                onChangeImageTimeout(e, index);
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {row.type == 1 && row.image_timeout < 5 && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              Timeout must be 5 or greater.{" "}
                            </p>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <DeleteIcon
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => deleteImage(index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Box style={{ float: "right", marginTop: "40px" }}>
          <Button
            variant="contained"
            sx={{
              marginRight: "10px",
              backgroundColor: "#32303B",
              "&:hover": {
                backgroundColor: "#32303B", // Change the color on hover
              },
            }}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: appTheme.btnColor,
              "&:hover": {
                backgroundColor: appTheme.btnColor, // Change the color on hover
              },
            }}
          >
            Save
          </Button>
        </Box>
        <Message type={type} msg={loadMessage} />
      </Box>
    </>
  );
}
